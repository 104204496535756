import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    GET_OBSERVER_ITEM_SUBACCOUNTS,
    GET_OBSERVER_ITEMS,
    THEME,
} from '@/store/global/variables'
import {
    getItemsObserverLink,
    getObserverSubAccounts,
    IObserverLinkItem,
    ISubAccountInfoDto,
} from '@/entities/cabinet'
import { ITheme } from '@/entities'

export interface IObserverItemState {
    loading: boolean
    observerItem: Partial<IObserverLinkItem>
    subaccounts: ISubAccountInfoDto[]
    currenSubAccount: string | null
    theme: ITheme
}
const initialState: IObserverItemState = {
    loading: false,
    observerItem: {},
    subaccounts: [],
    theme: (localStorage.getItem(THEME) as ITheme) || 'light',
    currenSubAccount: null,
}

const getObserverItems$ = createAsyncThunk(
    GET_OBSERVER_ITEMS,
    async ({
        token,
        params,
    }: {
        token: string
        params?: {
            start_date: string
            end_date: string
            sub_account_name: string
        }
    }) => {
        const response = await getItemsObserverLink(token, params)
        return Promise.resolve(response)
    }
)

const getObserverItemSubAccounts$ = createAsyncThunk(
    GET_OBSERVER_ITEM_SUBACCOUNTS,
    async ({
        token,
        params,
    }: {
        token: string
        params?: {
            start_date: string
            end_date: string
        }
    }) => {
        const response = await getObserverSubAccounts(token, params)
        return Promise.resolve(response)
    }
)

export const apiKeySlice = createSlice({
    name: 'reference',
    initialState,
    reducers: {
        changeGlobalSubAccount(state, { payload }) {
            state.currenSubAccount = payload
        },
        changeTheme(state, { payload }) {
            state.theme = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getObserverItems$.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getObserverItems$.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getObserverItems$.fulfilled, (state, action) => {
            state.observerItem = action.payload as any
            state.loading = false
        })
        builder.addCase(
            getObserverItemSubAccounts$.fulfilled,
            (state, action) => {
                state.subaccounts = action.payload as any
                if (state.subaccounts.length) {
                    state.currenSubAccount = state.subaccounts[0].name
                }
            }
        )
    },
})

export const globalReducer = apiKeySlice.reducer
export const { changeGlobalSubAccount, changeTheme } = apiKeySlice.actions
export { getObserverItems$, getObserverItemSubAccounts$ }
