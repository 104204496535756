export function toHashrate(hashrate: number): string {
    if (hashrate < 1_000_000_000) {
        return (hashrate / 1_000_000).toFixed(3) + ' TH/s'
    } else if (hashrate < 1_000_000_000_000) {
        return (hashrate / 1_000_000_000).toFixed(3) + ' PH/s'
    } else {
        return (hashrate / 1_000_000_000_000).toFixed(3) + ' EH/s'
    }
}
export function getHashrateValue(hashrate: number): {
    val: string
    unit: string
} {
    if (hashrate < 1_000_000_000) {
        return {
            val: (hashrate / 1_000_000).toFixed(3),
            unit: ' TH/s',
        }
    } else if (hashrate < 1_000_000_000_000) {
        return {
            val: (hashrate / 1_000_000_000).toFixed(3),
            unit: ' PH/s',
        }
    } else {
        return {
            val: (hashrate / 1_000_000_000_000).toFixed(3),
            unit: ' EH/s',
        }
    }
}
export function expo(x: number, f: number) {
    return Number.parseFloat(x as any).toExponential(f)
}
