import { useEffect, useState } from 'react'
import { LANG_STORAGE_KEY, USER_INFO } from '@/shared/constants'
import { IUser } from '@/entities'

export * from './use-dialog'
export function useGlobalParams() {
    const [lang, setLang] = useState<string>(
        localStorage.getItem(LANG_STORAGE_KEY) || 'en'
    )
    const [currentUser, setCurrentUser] = useState<{
        created_date: string
        user: IUser
    } | null>(
        localStorage.getItem(USER_INFO)
            ? JSON.parse(localStorage.getItem(USER_INFO) as string)
            : null
    )
    const openMenu = useState<boolean>(false)
    const sidebarState = useState<boolean>(false)
    const [isLogged, setIsLogged] = useState<boolean>(!!currentUser)
    const [theme, setTheme] = useState<'dark' | 'normal'>('normal')
    const changeTheme = (value: 'dark' | 'normal') => {
        setTheme(value)
    }
    const changeLanguage = (lang: string) => {
        setLang(lang)
        localStorage.setItem(LANG_STORAGE_KEY, lang)
    }
    useEffect(() => {
        if (currentUser) {
            const givenDate = new Date(currentUser.created_date)
            givenDate.setDate(givenDate.getDate() + 60)
            if (new Date() < givenDate) {
                setIsLogged(true)
            } else {
                setIsLogged(false)
                setCurrentUser(null)
            }
        }
    }, [currentUser])
    return {
        lang,
        isLogged,
        theme,
        setLang,
        changeTheme,
        sidebarState,
        setIsLogged,
        openMenu,
        changeLanguage,
        setCurrentUser,
        currentUser,
    }
}
