import style from './index.module.scss'
import { ADD_SUBACCOUNT, useSubAccount } from '@/entities/cabinet'
import React, { useContext, useState } from 'react'
import { GlobalContext } from '@/entities'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { CDropdown } from '@/shared/ui/cabinet'
import {
    setCurrentSubAccount,
    setCurrentSubAccountInfo,
    setDialogs,
} from '@/store/sub_accounts'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { changeGlobalSubAccount } from '@/store/global'
import { ELANG, LanguageComponent, useTheme } from '@/features/global'
import { useTranslations } from 'use-intl'
import { DarkModeToggle } from '@/features'
import Logo from '@/shared/ui/Logo'

const enum URLS {
    observerLink = '/observer-link',
}

function Component({ subAccountName }: { subAccountName?: string }) {
    const { currentUser } = useContext(GlobalContext)
    const { currentSubAccountInfo, subAccounts, currentSubAccount } =
        useSubAccount()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [showLangs, setShowLangs] = useState<boolean>(false)
    const i18n = useTranslations('cabinet.sidebar')
    const { currentTheme } = useTheme()

    const { lang } = useContext(GlobalContext)
    const subaccounts = useSelector(
        (state: RootState) => state.global.subaccounts
    )
    return (
        <header
            className={classNames(
                style.header,
                pathname.includes(URLS.observerLink)
                    ? style.header__observer
                    : ''
            )}
        >
            <div className={style.header__left}>
                <Link
                    to="/"
                    className={
                        pathname.includes(URLS.observerLink)
                            ? style.header__observer__logo
                            : style.header__left__logo
                    }
                >
                    {currentTheme === 'dark' ? (
                        <Logo />
                    ) : (
                        <img
                            src={require('@/shared/images/main_logo.png')}
                            width={130}
                            alt={'main logo'}
                        />
                    )}
                </Link>
                {!pathname.includes(URLS.observerLink) && (
                    <span>
                        BTC(
                        {currentSubAccountInfo &&
                            currentSubAccountInfo.earning_scheme}
                        ):{' '}
                        {(+(currentSubAccountInfo.commission || '0')).toFixed(
                            3
                        )}
                        %
                    </span>
                )}
            </div>
            <div className={style.header__right}>
                {pathname.includes(URLS.observerLink) && (
                    <CDropdown
                        options={subaccounts.map((account) => ({
                            label: account.name,
                            key: account.name,
                        }))}
                        value={subAccountName}
                        customList={<div></div>}
                        onChange={(item) => {
                            dispatch(changeGlobalSubAccount(item))
                        }}
                    />
                )}
                <div className={style.header__right__lang}>
                    <button onClick={() => setShowLangs((prev) => !prev)}>
                        <span>{ELANG[lang]}</span>
                        <img
                            src={
                                require('@/shared/images/svg/bottom-arrow.svg')
                                    .default
                            }
                            alt="lang"
                        />
                    </button>
                    {showLangs && (
                        <LanguageComponent setShowLangs={setShowLangs} />
                    )}
                </div>
                {!pathname.includes(URLS.observerLink) && (
                    <div className={style.header__right__dropdown}>
                        <CDropdown
                            options={subAccounts.map((account) => ({
                                label: account.name,
                                key: account.name,
                            }))}
                            customList={
                                <div
                                    onClick={() =>
                                        dispatch(
                                            setDialogs({
                                                key: ADD_SUBACCOUNT,
                                                value: true,
                                            })
                                        )
                                    }
                                >
                                    {i18n('create')}
                                </div>
                            }
                            value={currentSubAccount}
                            onChange={(val) => {
                                dispatch(setCurrentSubAccount(val))
                                dispatch(
                                    setCurrentSubAccountInfo(
                                        subAccounts.find(
                                            (acc) => acc.name === val
                                        )
                                    )
                                )
                            }}
                        />
                    </div>
                )}
                {currentUser && !pathname.includes(URLS.observerLink) && (
                    <>
                        <button onClick={() => navigate('/cabinet/settings')}>
                            <div className={style.header__right__user}>
                                {(currentUser.user?.username &&
                                    currentUser.user?.username[0]) ||
                                    'B'}
                            </div>
                        </button>
                    </>
                )}
                <DarkModeToggle />
            </div>
        </header>
    )
}

export const CHeader = React.memo(Component)
