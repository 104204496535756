import { useState } from 'react'
import { toHashrate } from '@/shared/utils'
import { CHART_INTERVALS, useChangingTheme } from '@/features'

export function useChart() {
    const { isDark } = useChangingTheme()

    const [chartState, setChartState] = useState({
        series: [
            {
                name: 'Hashrate: ',
                data: [] as any,
            },
        ],
        options: {
            chart: {
                id: 'area-datetime',
                type: 'area',
                height: 350,
                zoom: {
                    autoScaleYaxis: true,
                },
                toolbar: {
                    show: false,
                },
            },
            annotations: {},
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                min: new Date().setDate(new Date().getDate() - 100),
                tickAmount: 3,
                borderColor: '#40c067',
                labels: {
                    datetimeUTC: false,
                    style: {
                        colors: '#afafaf',
                    },
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                },
            },
            yaxis: {
                type: 'category',
                borderColor: '#40c067',
                labels: {
                    style: {
                        colors: '#afafaf',
                    },
                    formatter: function (val: any) {
                        return toHashrate(val)
                    },
                },
            },
            stroke: {
                colors: ['#49c36f'],
                width: 2,
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy HH:mm',
                },
            },
            fill: {
                colors: '#40c067',
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.5,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
        },
        selection: 'one_year',
    })

    const setChartStateByInterval = (
        res: { hashrates: any; now: string },
        interval: string
    ) => {
        const { hashrates, now } = res
        const data = []
        const newHashrates = [...hashrates]
        switch (interval) {
            case CHART_INTERVALS.oneHour.key:
                newHashrates.splice(0, 0, 0)
                for (let x = newHashrates.length - 1; x >= 0; x--) {
                    const newDate = new Date(now)
                    newDate.setMinutes(
                        newDate.getMinutes() - (newHashrates.length - x) * 5
                    )
                    data.push([newDate, newHashrates[x]])
                }
                const currentDate = new Date(now)
                setChartState({
                    ...chartState,
                    series: [{ data: data, name: 'Hashrate: ' }],
                    options: {
                        ...chartState.options,
                        xaxis: {
                            ...chartState.options.xaxis,
                            min: currentDate.setHours(
                                new Date(now).getHours() - 1
                            ),
                        },
                    },
                })
                break
            case CHART_INTERVALS.oneDay.key:
                newHashrates.splice(0, 0, 0)
                for (let x = newHashrates.length - 1; x >= 0; x--) {
                    const newDate = new Date(now)
                    newDate.setMinutes(
                        newDate.getMinutes() - (newHashrates.length - x) * 30
                    )
                    data.push([newDate, newHashrates[x]])
                }
                const currenDay = new Date(now)
                setChartState({
                    ...chartState,
                    series: [{ data: data, name: 'Hashrate: ' }],
                    options: {
                        ...chartState.options,
                        xaxis: {
                            ...chartState.options.xaxis,
                            min: currenDay.setHours(
                                new Date(now).getHours() - 24
                            ),
                        },
                    },
                })
                break
            case CHART_INTERVALS.oneMonth.key:
                newHashrates.splice(0, 0, 0)
                for (let x = newHashrates.length - 1; x >= 0; x--) {
                    const newDate = new Date(now)
                    newDate.setDate(
                        newDate.getDate() + 1 - (newHashrates.length - x)
                    )
                    data.push([newDate, newHashrates[x]])
                }
                setChartState({
                    ...chartState,
                    series: [{ data: data, name: 'Hashrate: ' }],
                    options: {
                        ...chartState.options,
                        xaxis: {
                            ...chartState.options.xaxis,
                            min: new Date(now).setDate(
                                new Date(now).getDate() - 30
                            ),
                        },
                    },
                })
                break
        }
    }

    return {
        chartState,
        setChartStateByInterval,
        setChartState,
    }
}
